.dropZone {
  flex: 0 0 auto;
  height: 40px;
  transition: 200ms all;
}

.dropZone:nth-of-type(2n) {
  display: none;
}

.dropZone.active,
.trashDropZone.active {
  background: #00a2ff;
  transition: 100ms all;
}
